import styled from "styled-components";

import { Title } from "../../sdk/Layout";

export const Items = styled.div`
    display: flex;
    flex-wrap: wrap;
    > a {
        cursor: pointer;
        flex-grow: 1;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        width: 48%;
        margin: 1%;
        min-height: 250px;
        @media (max-width: 768px) {
            width: 98%;
        }
    }
`;

export const Item = styled.div`
    &:before {
        content: "";
        display: block;
        padding-top: 50%;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    > div {
        width: 95%;
        max-width: 350px;
        min-height: 153px;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 20px;
        padding-bottom: 30px;
        border-radius: 5px 0px 0px 0px;
        background: ${props => props.theme.blueLightAlpha};
        transition: all 200ms linear;
        span {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: ${props => props.theme.gray};
            letter-spacing: 0;
            margin-bottom: 10px;
            display: block;
        }
        & ${Title} {
            font-family: futura;
            margin-bottom: 10px;
            padding: 0;
        }
        p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            color: ${props => props.theme.primaryDark};
        }
        svg {
            position: absolute;
            bottom: 5px;
            right: 15px;
            width: 25px;
            opacity: 0.2;
        }
        @media (max-width: 980px) {
            > div {
                max-width: 280px;
            }
            & ${Title} {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
        @media (max-width: 768px) {
            min-height: auto;
            width: auto;
            p {
                display: none;
            }
        }
    }
    &:hover {
        > div {
            background: ${props => props.theme.blueLight};
        }
        img {
            @media (min-width: 769px) {
                width: 103%;
                height: 103%;
            }
        }
    }
`;
