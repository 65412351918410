import React from "react";
import InternalLink from "../InternalLink";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { giveMeOldOrNewImage } from "../NoritexImage";
import { Container, Section, Title } from "../../sdk/Layout";
import Icons from "../Icons2";
import { Items, Item } from "./style";

function Mosaics({ config }) {
    if (config != null && config.length <= 0) return null;

    return (
        <Section>
            <Container isFluid>
                <Items>
                    {config.map((index, i) => (
                        <InternalLink
                            {...index.href}
                            key={`mosaic-${giveMeOldOrNewImage(index.img)}`}
                        >
                            <Item>
                                <LazyLoadImage
                                    src={`${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=700x700`}
                                    srcSet={` 
                                    ${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=300x300 300w,
                                    ${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=400x400 400w,
                                    ${giveMeOldOrNewImage(
                                        index.img
                                    )}?d=700x700 700w
                                    
                                    `}
                                    alt={index.alt}
                                />
                                <div>
                                    <span>{index.subTitle}</span>
                                    <Title as="h3" size="4">
                                        {index.title}
                                    </Title>
                                    <p>{index.p}</p>
                                    <Icons icon="long-arrow-right"></Icons>
                                </div>
                            </Item>
                        </InternalLink>
                    ))}
                </Items>
            </Container>
        </Section>
    );
}

export default Mosaics;
