import styled from "styled-components";
import { Section } from "../../sdk/Layout";

export const Wrapper = styled(Section)`
    background-color: ${props => props.theme.grayLight};
`;

export const MainHeading = styled.div`
    padding-bottom: 30px;
    text-align: center;
    h2 {
        padding: 0;
        margin: 0;
        font-family: futura;
        font-weight: 500;
        font-size: 30px;
        color: ${props => props.theme.primaryDarkMode};
        letter-spacing: 6.5px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 18px;
        color: ${props => props.theme.primaryDark};
        letter-spacing: 0;
        text-align: center;
        line-height: 27px;
        width: 100%;
        max-width: 460px;
        margin: auto;
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 22px;
            letter-spacing: 3px;
        }
        p {
            font-size: 16px;
            line-height: 18px;
        }
    }
`;

export const Brands = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5%;
    margin-left: -0.5%;
    > a {
        margin: 0.5%;
        /* flex-grow: 1; */
        width: 32.333%;
        position: relative;
        background-color: #000;
        display: block;
        cursor: pointer;
        @media (max-width: 980px) {
            width: 49%;
        }
    }
`;

export const Brand = styled.div`
    &:before {
        content: "";
        display: block;
        padding-top: 80%;
    }
    > img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        transition: all 200ms linear;
    }

    > div {
        position: absolute;
        left: 0;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        padding: 0px 10px;
        z-index: 2;
        img {
            width: 200px;
            transition: all 200ms linear;
            display: block;
            margin: auto;
            @media (max-width: 1215px) and (min-width: 981px) {
                width: 120px;
            }
            @media (max-width: 768px) {
                width: 120px;
            }
        }
        p {
            transition: all 50ms ease-in;
            margin: 0;
            padding: 0;
            font-size: 0;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            line-height: 22px;
            @media (max-width: 480px) {
                display: none;
            }
        }
    }
    &:hover {
        > img {
            opacity: 0.25;
        }
        > div {
            img {
                @media (min-width: 481px) {
                    margin-bottom: 10px;
                }
            }
            p {
                font-size: 16px;
                @media (max-width: 1215px) and (min-width: 981px) {
                    font-size: 14px;
                    line-height: 17px;
                }
                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
`;
